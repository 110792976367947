import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from "../components/seo";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';

const BlogPostTemplate = ({ data }) => {
  if (!data?.markdownRemark) {
    return (
      <>
        <Navigation />
        <div className="min-h-screen py-12">
          <div className="max-w-3xl mx-auto px-4">
            <h1>Post not found</h1>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  const post = data.markdownRemark;
  const { frontmatter, html, excerpt } = post;
  const seoDescription = frontmatter.description || excerpt;
  const recentPosts = data.recentPosts?.edges || [];
  const recentTils = data.recentTils?.edges || [];

  return (
    <>
      <Seo title={frontmatter.title} description={seoDescription} />
      <Navigation />
      <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <main className="lg:col-span-8 lg:col-start-1">
              <article className="space-y-8">
                <header className="space-y-4 mb-8">
                  <Link
                    to="/"
                    className="inline-block text-gray-600 hover:text-brand-600 mb-4"
                  >
                    <span className="flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                      </svg>
                      Back to Blog
                    </span>
                  </Link>
                  <div className="text-sm text-gray-600">{frontmatter.date}</div>
                  <h1 className="text-4xl text-brand-600 leading-tight tracking-tight">{frontmatter.title}</h1>
                </header>

                <section 
                  className="prose prose-lg max-w-none text-gray-800 leading-relaxed markdown
                            prose-headings:font-bold prose-headings:tracking-tight prose-headings:text-gray-900
                            prose-p:text-gray-800 prose-p:leading-relaxed
                            prose-a:text-brand-600 prose-a:no-underline hover:prose-a:underline
                            prose-strong:text-gray-900 prose-strong:font-bold
                            prose-code:text-gray-800 prose-code:bg-gray-100 prose-code:px-1 prose-code:rounded
                            prose-pre:bg-gray-50 prose-pre:text-gray-800
                            prose-img:rounded-lg prose-img:shadow-md
                            prose-blockquote:border-l-4 prose-blockquote:border-brand-200 prose-blockquote:text-gray-700 prose-blockquote:italic
                            prose-li:text-gray-800"
                  dangerouslySetInnerHTML={{ __html: html }} 
                />
                <div className="mt-8 pt-4 border-t text-sm text-gray-600">
                  Posted {frontmatter.date} · <a href="https://twitter.com/micadep" className="text-brand-600 hover:underline" target="_blank" rel="noopener noreferrer">Follow me on X</a>
                </div>
              </article>

              {/* Recent Posts section */}
              {recentPosts.length > 0 && (
                <div className="mt-16 border-t border-gray-200 pt-8">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6">Recent Articles</h2>
                  <div className="space-y-4">
                    {recentPosts.map(({ node }) => (
                      <Link 
                        key={node.fields.slug}
                        to={node.fields.slug}
                        className="block group"
                      >
                        <h3 className="text-lg font-semibold text-gray-800 group-hover:text-brand-600">
                          {node.frontmatter.title}
                        </h3>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </main>

            {/* Sidebar */}
            <aside className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-8 space-y-8">
                {/* Tags section */}
                {frontmatter.tags && frontmatter.tags.length > 0 && (
                  <div className="border-l-4 border-brand-200 pl-4">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Tags</h2>
                    <div className="flex flex-wrap gap-2">
                      {frontmatter.tags.map(tag => (
                        <Link
                          key={tag}
                          to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                          className="text-sm text-gray-600 hover:text-brand-600"
                        >
                          #{tag}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}

                {/* TILs section */}
                {recentTils.length > 0 && (
                  <div className="border-l-4 border-brand-200 pl-4">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Today I Learned</h2>
                    <div className="space-y-3">
                      {recentTils.map(({ node }) => (
                        <Link 
                          key={node.fields.slug}
                          to={node.fields.slug}
                          className="block text-gray-700 hover:text-brand-600"
                        >
                          {node.frontmatter.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostBySlug($id: String!, $slug: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "Do MMMM YYYY [at] h:mm a")
        description
        tags
      }
      fileAbsolutePath
    }
    recentPosts: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        fields: { slug: { ne: $slug } }
        fileAbsolutePath: { regex: "/content/blog/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    recentTils: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        fileAbsolutePath: { regex: "/content/til/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
